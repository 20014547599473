<template>
  <div class="pb-md-5 pb-2">
    <h4 class="font-weight-bold">
      Ja, ik wil een <span class="text_green">gratis</span> en vrijblijvende
      offerte ontvangen voor zonnepanelen en besparen op mijn energiekosten.
    </h4>
    <div class="my-4">
      <b-form @submit.prevent="onSubmit">
        <b-row>
          <div class="col-12 col-md-6">
            <b-form-group id="fName" label="Voornaam" label-for="fName">
              <b-form-input
                id="fName"
                v-model="$v.second_form.first_name.$model"
                :state="validateState('first_name')"
                placeholder="bv. Pieter"
                required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-12 col-md-6">
            <b-form-group id="lName" label="Achternaam" label-for="lName">
              <b-form-input
                id="lName"
                v-model="$v.second_form.last_name.$model"
                :state="validateState('last_name')"
                placeholder="bv. de Jong"
                required
              ></b-form-input>
            </b-form-group>
          </div>
        </b-row>
        <b-row>
          <div class="col-12 col-md-6">
            <b-form-group id="tel" label="Telefoonnummer" label-for="tel">
              <b-form-input
                id="tel"
                type="tel"
                maxlength="10"
                v-model="$v.second_form.phone_number.$model"
                :state="validateState('phone_number')"
                placeholder="bv. 0412345678"
                required
              ></b-form-input>
            </b-form-group>
          </div>
          <div class="col-12 col-md-6">
            <label for="email">E-mail</label>
            <b-form-input
              v-model="$v.second_form.email.$model"
              :state="validateState('email')"
              type="email"
              id="email"
              placeholder="bv. piet@dejong.nl"
            ></b-form-input>
          </div>
        </b-row>

        <!--  -->
        <!-- <b-form-group
          id="when"
          label="Wanneer wil je de kozijnen geplaatst hebben?"
          label-for="when"
        >
          <b-form-select
            id="when"
            v-model="$v.second_form.when.$model"
            :state="validateState('when')"
            :options="when"
            required
          ></b-form-select>
        </b-form-group> -->
        <!--  -->
        <b-col lg="12" class="pt-4 pb-2 px-0"
          ><b-button
            size="lg"
            type="submit"
            variant="success"
            class="w-100 py-3"
            style="background: #418d4a; border: #418d4a"
          >
            <b-spinner v-if="getLoader" /><span v-else
              ><b>
                Gratis offerte ontvangen<i
                  class="fas fa-angle-right align-middle ml-1"
                ></i> </b></span></b-button
        ></b-col>
      </b-form>
      <div class="text-center px-2">
        <small class="font10">
          Door de aanvraag te versturen, geeft u toestemming aan BeliVert om de
          hierboven ingediende persoonlijke informatie op te slaan en te
          verwerken om u van de gevraagde inhoud te voorzien.
          <!-- <span class="cursor-pointer" v-b-modal.Algemene>
            Algemene voorwaarden</span
          > -->
        </small>
      </div>
    </div>
    <!-- modal -->
    <b-modal
      id="Algemene"
      centered
      title="Algemene voorwaarden"
      ok-only
      size="lg"
      ok-variant="success"
      scrollable
    >
      <div>
        <p>
          Via Offertevergelijker.nl vragen dagelijks vele Nederlanders offertes
          aan voor zijn of haar klus. Nadat je een offerteaanvraag hebt
          doorgestuurd via één van onze formulieren, bekijken wij de aanvraag en
          indien deze akkoord is, zoeken wij maximaal drie bedrijven waarvan wij
          denken dat ze u een goede aanbieding kunnen doen. Het aanvragen van
          offertes is gratis en vrijblijvend.
        </p>
        <h4>Persoonsgegevens</h4>
        <p>
          Om een offerteaanvraag door te kunnen zetten naar relevante partners,
          hebben wij enkele persoonsgegevens van u nodig. Onder persoonsgegevens
          verstaan wij ieder gegeven dat je aan ons doorgeeft, waarmee jij als
          persoon aanwijsbaar bent. Voorbeelden van persoonsgegevens zijn je
          naam en adres.
        </p>
        <p>
          Wij vragen tevens om een telefoonnummer. Deze vragen wij, omdat de
          geselecteerde bedrijven bij jouw aanvraag in sommige gevallen
          aanvullende vragen hebben. Zo kunnen zij de offerte zo op maat
          mogelijk maken en krijgt u het beste overzicht van de mogelijkheden.
        </p>
        <h4>Geen garantie</h4>
        <p>
          Bij Offertevergelijker brengen wij particulieren en bedrijven bij
          elkaar. De rol van Offertevergelijker.nl gaat nooit verder dan het
          faciliteren van vraag en aanbod. Wij geven geen garantie op de
          geleverde diensten of uitgevoerde werkzaamheden. Offertevergelijker.nl
          is dan ook niet aansprakelijk voor eventuele schade door of
          onwenselijk gedrag van het uitvoerende bedrijf. U blijft te allen
          tijde zelf verantwoordelijk voor het vastleggen van goede afspraken
          met het bedrijf dat uw werkzaamheden uitvoert.
        </p>
        <h4>Wijzigingen algemene voorwaarden</h4>
        <p>
          Offertevergelijker heeft het recht om de informatie op deze website
          aan te passen en kan dit doen zonder hiervan enige kennisgeving
          daarvan.
        </p>
        <p>
          Door onze website te bezoeken, ga je automatisch akkoord met deze
          algemene voorwaarden.
        </p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, alphaNum, email, numeric } from "vuelidate/lib/validators";
import { helpers } from "vuelidate/lib/validators";
const alphaName = helpers.regex("alpha", /^([a-zA-Z ]){1,30}$/);
const alphaPno = helpers.regex("alpha", /^0\d{8,10}$/);

export default {
  name: "SecondForm",
  mixins: [validationMixin],
  data() {
    return {
      second_form: {
        when: "null",
        first_name: "",
        last_name: "",
        phone_number: "",
        email: "",
        site_subid: "",
        publisher_id: "",
      },
      when: [
        { text: "Selecteer wanneer", value: null, disabled: true },
        { text: "In overleg", value: "3796", disabled: false },
        { text: "Binnen 3 maanden", value: "3799", disabled: false },
        { text: "Binnen 3 tot 6 maanden", value: "3802", disabled: false },
      ],
    };
  },
  methods: {
    ...mapActions(["postLead"]),
    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        let form = {
          language: "nl_BE",
          firstname: this.second_form.first_name,
          lastname: this.second_form.last_name,
          email: this.second_form.email,
          phone_number: this.second_form.phone_number,
          zip: this.first_form.zip,
          city: this.city,
          street: this.street,
          house_number: this.first_form.house_number,
          answers: ["3238"],
          site_subid: this.$route.query.oa_id,
          publisher_id: this.$route.query.site,
          site_custom_url: "http://belivert.verbeterthuis.be",
          site_custom_name: "Belivert_page2",
        };
        let resp = null;
        if (this.city == null || this.street == null) {
          const { city, street, ...exclude } = form;
          resp = await this.postLead(exclude);
          if (resp.success == true) {
            // alert("new page");
            this.$router.push("/v2/bedankt");
          }
          // resp = await console.log(exclude);
        } else {
          resp = await this.postLead(form);
          // resp = await console.log(form);
          if (resp.success == true) {
            // alert("new page");
            this.$router.push("/v2/bedankt");
          }
        }

        // console.log(this.form, "form");
      }
    },
    // validations
    // validateState(kWh) {
    //   const { $dirty, $error } = this.$v.second_form[kWh];
    //   return $dirty ? !$error : null;
    // },
    validateState(when) {
      const { $dirty, $error } = this.$v.second_form[when];
      return $dirty ? !$error : null;
    },
    validateState(first_name) {
      const { $dirty, $error } = this.$v.second_form[first_name];
      return $dirty ? !$error : null;
    },
    validateState(last_name) {
      const { $dirty, $error } = this.$v.second_form[last_name];
      return $dirty ? !$error : null;
    },
    validateState(phone_number) {
      const { $dirty, $error } = this.$v.second_form[phone_number];
      return $dirty ? !$error : null;
    },
    validateState(email) {
      const { $dirty, $error } = this.$v.second_form[email];
      return $dirty ? !$error : null;
    },
  },
  validations: {
    second_form: {
      // kWh: {
      //   required,
      // },
      when: {
        required,
      },
      first_name: {
        required,
        // alphaNum,
        alphaName,
      },
      last_name: {
        required,
        // alphaNum,
        alphaName,
      },
      phone_number: {
        required,
        numeric,
        alphaPno,
      },
      email: {
        required,
        email,
      },
    },
  },

  computed: { ...mapGetters(["getLoader", "first_form", "city", "street"]) },
};
</script>

<style>
h4 {
  font-size: 22px !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.text_green {
  color: #418d4a !important;
}
.font10 {
  font-size: 10px;
}
.bg-secondary {
  background: #418d4a !important;
}
</style>
