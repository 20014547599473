<template>
  <div>
    <div
      v-if="fullLoader"
      class="d-flex justify-content-center align-items-center"
      style="height: 100vh"
    >
      <b-spinner class="text-success" />
    </div>
    <b-container fluid="lg" v-else class="pt-md-5 pb-0">
      <b-row cols=" pt-0 pt-md-4 pb-md-3 pb-0">
        <div class="col-12 col-md-6 col-lg-6">
          <FormLeft />
        </div>
        <!--  -->
        <div class="col-12 col-md-6">
          <div class="bg-white shadow px-3 px-md-4 banner__form py-md-5 py-3">
            <SecondForm />
          </div>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FormLeft from "./FormLeft.vue";
import SecondForm from "./SecondForm.vue";
export default {
  name: "SecondFormSection",
  components: { SecondForm, FormLeft },
  computed: mapGetters([
    "lng",
    "lat",
    "zip",
    "first_form",
    "street",
    "cityName",
    "city",
    "fullLoader",
  ]),
};
</script>

<style lang="scss" scoped>
.text-success {
  color: #418d4a !important;
}
.text-green {
  color: #418d4a !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.banner {
  &__form {
    border-radius: 8px !important;
    border-left: 2px solid #eee !important;
    border-color: #418d4a !important;
  }
  .borderblue {
    border: 3px solid #3667b2;
    color: #3667b2;
    border-radius: 7px;
    padding: 12px 15px 15px;
  }
}
</style>
