import Repository from "./Repository";
import StreetRepository from "./StreetRepository";

const LEADS = "leads";
import qs from "qs";

export default {
  getStreet(payload) {
    // console.log(payload, "payload in Repostory");
    return StreetRepository.get(
      `?postcode=${payload.zip}&number=${payload.house_number}`
    );
  },
  postLead(payload) {
    return Repository.post(`${LEADS}`, qs.stringify(payload));
  },
};
