import { RepositoryFactory } from "../../repository/RepositoryFactory";

let leads = RepositoryFactory.get("leads");
const state = {
  getLoader: false,
  id: null,
  temp_form: {},
  secondFormShow: false,
  thirdFormShow: false,
  getErrorResponse: {}
};

const getters = {
  getLoader: (state) => state.getLoader,
  profId: (state) => state.id,
  first_form: (state) => state.temp_form,
  secondFormShow: (state) => state.secondFormShow,
  thirdFormShow: (state) => state.thirdFormShow,
  getErrorResponses: (state) => state.getErrorResponse,
};

const actions = {
  async postLead({ commit }, payload) {
    commit("setGetLoader", true);
    //test
    // const testError = "Validation error for 'last_name'";
    // const error = testError.match(/'([^']+)'/)[0].slice(1, -1);
    // commit("setError", { [error]: false });
    // commit("setGetLoader", false);
    // return {
    //   success: false,

    // };
    try {


      if (payload.bootCheck == true) {
        commit("setGetLoader", false);

        return {
          success: true,
        };
      }
      else {

        const response = await leads.postLead(payload);
        commit("setGetLoader", false);
        const responseid = response.data.profile.id;
        commit("id", responseid);
        return {
          success: true,
          profileid: responseid,
        };
      }
    } catch (error) {
      // commit("setGetLoader", false);
      // console.log(error.response.data.error);
      // commit("id", null);
      // return {
      //   success: true,
      //   profileid: null,
      // };
      commit("setGetLoader", false);
      if (error.response && error.response.data.error === "Conflict - duplicate") {
        console.log("error=>", error.response.data.error)

        commit("id", null);
        return {
          success: true,
          profileid: null,

        };
      } else {
        const errorKey = error.response.data.error.match(/'([^']+)'/)[0].slice(1, -1);
        commit("setError", { [errorKey]: false });
        console.error("Error Detail:", errorKey);
        return {
          success: false,
        };
      }
    }
  },
  async temp_formData({ commit }, temp_data) {
    state.temp_form = temp_data;
    if (state.temp_form.koop == "3277") {
      state.secondFormShow = !state.secondFormShow;
    } else {
      state.thirdFormShow = !state.thirdFormShow;
    }
  },
  async toggleSecondForm({ commit }) {
    state.secondFormShow = !state.secondFormShow;
    state.thirdFormShow = !state.thirdFormShow;
  },
  async closeSecondForm({ commit }) {
    state.secondFormShow = false;
  },
};

const mutations = {
  setGetLoader(state, payload) {
    state.getLoader = payload;
  },
  id(state, payload) {
    state.id = payload;
  },
  setError(state, payload) {
    state.getErrorResponse = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
