import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Homev2 from "../views/Homev2.vue";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   name: "Home",
  //   component: Home,
  //   redirect: "/v1",
  // },
  // {
  //   path: "/v1",
  //   name: "Home",
  //   component: Home,
  // },
  // {
  //   path: "/v1/bedankt",
  //   name: "Bedankt",
  //   component: () => import("../views/Bedankt.vue"),
  // },

  // {
  //   path: "/v2",
  //   name: "Homev2",
  //   component: Homev2,
  // },
  // {
  //   path: "/v2/bedankt",
  //   name: "Bedanktv2",
  //   component: () => import("../views/Bedanktv2.vue"),
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
