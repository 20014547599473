import { RepositoryFactory } from "../../repository/RepositoryFactory";

var newStreet = RepositoryFactory.get("leads");

export const getStreet =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    console.log(payload, "payload");

    try {
      let { data } = await newStreet.getStreet(payload);
      if (data) {
        dispatch({
          type: "NEW_STREET",
          payload: data,
        });
        onSuccess();
      }
    } catch (e) {
      console.log(e.response.data.error);
    }
  };
