<template>
  <div>
    <b-form @submit.prevent="onSubmit">
      <b-row>
        <!-- <div class="col-12 col-md-9">
          <b-form-group
            id="Straatnaam"
            label="Straatnaam"
            label-for="Straatnaam"
          >
            <b-form-input
              id="Straatnaam"
              v-model="$v.temp_form.straatnaam.$model"
              placeholder="bv. Avenue Albert"
              type="text"
              :state="validateState('straatnaam')"
              required
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-12 col-md-3">
          <label for="house">Huisnummer</label>
          <b-form-input
            v-model="$v.temp_form.house_number.$model"
            type="text"
            id="house"
            :state="validateState('house_number')"
            placeholder="bv. 12"
          ></b-form-input>
        </div> -->
        <div class="col-12">
          <b-form-group id="Postcode" label="Postcode" label-for="Postcode">
            <b-form-input
              id="Postcode"
              class="onlyPostcode"
              v-model="$v.temp_form.zip.$model"
              placeholder="bv. 2000"
              type="text"
              @input="handleFieldInput('zip')"
              :state="validateState('zip')"
              :class="this.getErrorResponses.zip == false ? 'is-invalid' : ''"
              aria-describedby="input-1-live-feedback"
              required
            ></b-form-input>
            <b-form-invalid-feedback
              v-if="!$v.temp_form.zip.required || !getErrorResponses.zip"
            >
              Postcode niet correct
            </b-form-invalid-feedback>
          </b-form-group>
        </div>
        <!-- <div class="col-12 col-md-8">
          <label for="city">Plaatsnaam</label>
          <b-form-input
            v-model="$v.temp_form.city.$model"
            type="text"
            id="city"
            :state="validateState('city')"
            placeholder="bv. Namur"
          ></b-form-input>
        </div> -->
      </b-row>
      <b-row>
        <b-col lg="12" class="mt-3"
          ><b-button
            size="lg"
            type="submit"
            variant="success"
            class="w-100 py-3"
            style="background: #e49538; border: #74a301"
          >
            <span
              ><b> Check of mijn woning in <br />aanmerking komt </b></span
            ></b-button
          ></b-col
        ></b-row
      >
      <div class="formfooter__rating d-flex justify-content-center my-3">
        <p class="mb-0">4,8/5</p>
        <div class="d-flex">
          <img
            src="../assets/star.png"
            width="20px"
            height="20px"
            class="mx-1"
          /><img
            src="../assets/star.png"
            width="20px"
            height="20px"
            class="mx-1"
          /><img
            src="../assets/star.png"
            width="20px"
            height="20px"
            class="mx-1"
          /><img
            src="../assets/star.png"
            width="20px"
            height="20px"
            class="mx-1"
          /><img
            src="../assets/star.png"
            width="20px"
            height="20px"
            class="mx-1"
          /><img
            src="../assets/google.png"
            width="20px"
            height="20px"
            class="mx-1"
          />
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import {
  required,
  minLength,
  alphaNum,
  numeric,
  maxLength,
} from "vuelidate/lib/validators";
import { helpers } from "vuelidate/lib/validators";
const alphaName = helpers.regex("alpha", /^([a-zA-Z ]){1,30}$/);

export default {
  name: "FirstForm",
  mixins: [validationMixin],
  data() {
    return {
      temp_form: {
        zip: "",
        // house_number: "",
        // straatnaam: "",
        // city: "",
        koop: "3277",
      },
    };
  },
  methods: {
    ...mapActions(["temp_formData", "getLocation"]),
    handleFieldInput(fieldName) {
      console.log("phone number changed", fieldName);
      if (this.getErrorResponses && !this.getErrorResponses[fieldName]) {
        console.log("phone number changed2", fieldName);
        this.$store.commit("setError", {
          ...this.getErrorResponses,
          [fieldName]: true,
        });
      }
    },
    onSubmit() {
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        this.getLocation(this.temp_form);
        this.temp_formData(this.temp_form);
        window.scrollTo(0, 0);
      }
    },
    validateState(zip) {
      const { $dirty, $error } = this.$v.temp_form[zip];
      return $dirty ? !$error : null;
    },
    // validateState(house_number) {
    //   const { $dirty, $error } = this.$v.temp_form[house_number];
    //   return $dirty ? !$error : null;
    // },
    // validateState(straatnaam) {
    //   const { $dirty, $error } = this.$v.temp_form[straatnaam];
    //   return $dirty ? !$error : null;
    // },
    // validateState(city) {
    //   const { $dirty, $error } = this.$v.temp_form[city];
    //   return $dirty ? !$error : null;
    // },
  },
  validations: {
    temp_form: {
      zip: {
        required,
        maxLength: maxLength(4),
        minLength: minLength(4),
        numeric,
      },
      // house_number: {
      //   required,
      //   alphaNum,
      // },
      // straatnaam: {
      //   required,
      //   alphaName,
      // },
      // city: {
      //   required,
      //   alphaName,
      // },
    },
  },
  computed: {
    ...mapGetters(["getErrorResponses", "first_form"]),
  },
  mounted() {
    if (this.first_form.zip) {
      this.temp_form.zip = this.first_form.zip;
    }
  },
};
</script>

<style>
.onlyPostcode {
  height: calc(1.5em + 0.75rem + 20px) !important;
  font-size: 1.1rem !important;
}
.btnDiv {
  border: 2px solid #69326d;
  border-radius: 6px;
}
label.btn {
  padding: 0;
}

label.btn input {
  opacity: 0;
  position: absolute;
}

label.btn span {
  text-align: center;
  border-radius: 3px;
  padding: 12px 12px;
  display: block;
  margin: -2px;
  color: #69326d;
}

label.btn input:checked + span {
  background-color: #69326d;
  color: #fff;
  font-weight: 400;
}
.color--green {
  color: #74a301;
}
.formfooter__rating {
  font-weight: 700;
  font-size: 15px;
  text-align: center;
}
</style>
