<template>
  <div>
    <h3 class="font-weight-bold">
      Helaas komt jouw woning op dit moment niet in aanmerking voor gratis
      kunststof kozijnen offertes.
    </h3>
    <h5 class="text-secondary my-4">Je kunt het venster afsluiten</h5>
    <b-button class="btn-primary" @click="toggleSecondForm"
      >Ik heb wél een koopwoning</b-button
    >
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, alphaNum, email, numeric } from "vuelidate/lib/validators";

export default {
  name: "ThirdForm",
  mixins: [validationMixin],
  data() {
    return {
      second_form: {
        // kWh: "null",
        when: "null",
        first_name: "",
        last_name: "",
        phone_number: "",
        email: "",
      },
    };
  },
  methods: {
    ...mapActions(["toggleSecondForm"]),
  },
};
</script>

<style>
.btn-primary {
  background-color: #69326d !important;
  color: #fff;
}
.btn-primary:active {
  background-color: #69326d !important;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.text_secondary {
  color: #74a301 !important;
}
.font10 {
  font-size: 10px;
}
.bg-secondary {
  background: #74a301 !important;
}
</style>
