<template>
  <div>
    <div class="d-flex align-items-center ml-md-4 ml-2 mb-3">
      <a href="#"><img src="../assets/headerLogo1.png" width="85px" /></a>
      <a href="#"
        ><img src="../assets/headerLogo2.png" width="50px" class="mx-2"
      /></a>
      <a href="#"><img src="../assets/headerLogo3.png" width="90px" /></a>
    </div>
    <h3 class="font-weight-bold m-0">
      Goed nieuws! Jouw woning in postcodegebied
      <span class="text-green">
        <!-- {{ this.street }} {{ first_form.house_number
              }} -->
        {{ first_form.zip }}
      </span>
      in
      <!-- <span class="text-green">{{ this.cityName }} </span> -->
      komt in aanmerking voor gratis zonnepanelen offertes.
    </h3>
    <div
      class="borderblue h5 pb-3 px-3 pt-2 my-2 my-md-4 bg-white font-weight-bold"
    >
      <!-- <h5 class="m-0 font-weight-bold"> -->
      + geniet in {{ new Date().getFullYear() }} ook van een <br />premie vanuit
      de overheid.
      <!-- </h5> -->
    </div>
    <div class="my-2 my-md-4">
      <div>
        <GmapMap
          :options="{
            zoomControl: false,
            fullscreenControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: true,
            disableDefaultUi: false,
          }"
          :zoom="13"
          :center="{ lng: this.lng, lat: this.lat }"
          map-type-id="terrain"
          style="width: 100%; height: 350px"
          class="map-height"
        >
          <!-- <GmapMarker
                  :position="{
                    lng: this.lng,
                    lat: this.lat,
                  }"
                  :clickable="true"
                  :draggable="true"
                /> -->
        </GmapMap>
      </div>
    </div>
    <!-- <h3 class="text-">Bespaar tot 30% in maar 2 minuten!</h3> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: mapGetters([
    "lng",
    "lat",
    "zip",
    "first_form",
    "street",
    "cityName",
    "city",
    "fullLoader",
  ]),
};
</script>

<style lang="scss" scoped>
@media (max-width: 767.98px) {
  // h2 {
  //   font-size: 24px;
  // }
  h3 {
    font-size: 22px;
  }
  .map-height {
    height: 200px !important;
  }
}
.text-green {
  color: #418d4a !important;
}

.borderblue {
  border: 3px solid #3667b2;
  color: #3667b2;
  border-radius: 7px;
  padding: 12px 15px 15px;
}
</style>
