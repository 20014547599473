import axios from "axios";
const baseDomain =
  "https://cors-platform.herokuapp.com/https://json.api-postcode.nl";
const baseURL = `${baseDomain}`;
let axiosObj;
axiosObj = axios.create({
  baseURL,
  headers: { token: "652d0b86-9adc-4143-8c3e-00af15b80781" },
});
export default axiosObj;
