<template>
  <footer class="footer">
    <div class="container py-4">
      <div class="row">
        <div class="col-12 text-left">
          <!-- <p class="m-0">
            <small class="font-weight-bold">
              Offertevergelijker is dé vergelijkingswebsite voor bouw- en
              verbouwklussen van Nederland.</small
            >
          </p> -->
          <small
            class="cursor-pointer font-weight-bold"
            v-b-modal.PrivacyPolicy
          >
            Privacyverklaring &nbsp; </small
          ><small
            class="cursor-pointer font-weight-bold"
            v-b-modal.TermsConditions
          >
            &nbsp; Algemene voorwaarden</small
          >
        </div>
      </div>
    </div>
    <!-- Modals -->
    <div>
      <b-modal
        id="PrivacyPolicy"
        centered
        title="Privacy statement"
        ok-only
        size="lg"
        ok-variant="success"
        scrollable
      >
        <div class="px-3">
          <h4>Algemeen</h4>
          <p>
            Deze privacyverklaring heeft betrekking op de verwerking van uw
            persoonsgegevens door BeliVert tijdens alle interacties met ons,
            bijvoorbeeld wanneer u onze website bezoekt, gebruik maakt van onze
            dienstverlening, u zich abonneert op onze nieuwsbrieven, u contact
            opneemt met onze medewerkers, enzovoort.<br />BeliVert neemt uw
            privacy ernstig. Zij verwerkt de verkregen persoonsgegevens in
            overeenstemming met de beginselen inzake de verwerking van
            persoonsgegevens en de verplichtingen opgelegd door de Europese
            Verordening (EU) 2016/679 van het Europees Parlement en de Raad van
            27 april 2016 betreffende de bescherming van natuurlijke personen in
            verband met de verwerking van persoonsgegevens en betreffende het
            vrije verkeer van die gegevens en tot intrekking van Richtlijn
            95/46/EG, hetzij “de algemene verordening gegevensbescherming”
            (“AVG”) of “General Data Protection Regulation” (“GDPR”).<br /><br />
            Zo verwekt BeliVert de verkregen persoonsgegevens rekening houdende
            met de beginselen waaraan elke gegevensverwerking moet voldoen, met
            name
            <br />
            a) De persoonsgegevens worden verwerkt op een wijze die ten aanzien
            van de betrokkene rechtmatig, behoorlijk en transparant is
            („rechtmatigheid, behoorlijkheid en transparantie”);
            <br />
            b) De persoonsgegevens worden slechts verzameld voor welbepaalde,
            uitdrukkelijk omschreven en gerechtvaardigde doeleinden. BeliVert
            verwerkt deze niet verder op een met die doeleinden onverenigbare
            wijze („doelbinding”);
            <br />
            c) De persoonsgegevens moeten toereikende zijn, ter zake dienend en
            beperkt tot wat noodzakelijk is voor de doeleinden waarvoor zij
            worden verwerkt („minimale gegevensverwerking”);
            <br />
            d) De persoonsgegevens moeten juist zijn en zo nodig worden
            geactualiseerd; BeliVert neemt alle redelijke maatregelen om de
            persoonsgegevens die, gelet op de doeleinden waarvoor zij worden
            verwerkt, onjuist zijn, onverwijld te wissen of te rectificeren
            („juistheid”);
          </p>
          <h4>Welke persoonsgegevens worden er opgeslagen?</h4>
          <p>
            Onder persoonsgegevens verstaan we gegevens die op een
            identificeerbaar persoon betrekking hebben. Deze gegevens worden ons
            doorgegeven wanneer je bijvoorbeeld een formulier invult op de
            website, door het gebruik van onze producten en diensten, of een
            applicatie op deze website. Alle gegevens die we verzamelen kunnen
            met elkaar gecombineerd worden om onze aanbiedingen en diensten
            beter af te stemmen op je persoonlijke behoeften.
            <br />
            BeliVert verzamelt via de website volgende persoonsgegevens
          </p>
          <h5>Bij het invullen van het offerteformulier:</h5>
          <li>je naam, achternaam, e-mailadres, telefoonnummer en postcode</li>
          <p>
            Gedurende andere interacties waarbij informatie wordt uitgewisseld
            (consultaties via telefoon, e-mail, op kantoor, ….) verzamelen wij
            uitsluitend persoonsgegevens die noodzakelijk zijn voor de
            uitvoering van de overeenkomst.
          </p>
          <h4>Wie is er verantwoordelijk voor deze gegevens?</h4>
          <p>
            BeliVert BV, met maatschappelijke zetel te Schatting 42F, 8210
            Zedelgem en ingeschreven bij de KBO onder nummer 0831.630.290
            (hierna BeliVert) is verantwoordelijk voor de verwerking van je
            persoonsgegevens.
          </p>
          <p>
            T +32 (0)50 25 02 40
            <br />hello@belivert.be
          </p>
          <h4>Hoe worden deze gegevens beschermd?</h4>
          <p>
            We zetten alles op alles om je persoonsgegevens en privacy te
            beschermen, zowel in onze kantoren, op ons netwerk als op
            verplaatsing. Onze medewerkers worden opgeleid om zeer voorzichtig
            om te gaan met vertrouwelijke gegevens. Ook worden bij extra
            gevoelige gegevens extra maatregelen genomen.
          </p>
          <ul>
            <li>
              beveiliging met degelijke wachtwoorden die slechts gebruikt worden
              in één of enkele specifieke toepassingen
            </li>
            <li>versturing van de formuliergegevens via HTTPS</li>
            <li>
              degelijke, geupdate firewalls bij zowel onze kantoren als bij de
              hosting providers
            </li>
            <li>
              degelijke, geupdate servers bij zowel onze kantoren als bij de
              hosting providers
            </li>
            <li>intrusiedetectiesoftware op de firewalls</li>
            <li>
              slechts een beperkt aantal werknemers hebben toegang tot je
              persoonlijke informatie, enkel zij die deze informatie nodig
              hebben om hun taken naar behoren uit te voeren
            </li>
            <li>recente hardware met geupdate firmware</li>
            <li>fysiek beveiligd kantoor met alarmsysteem</li>
            <li>geen toegang van derden zonder aanwezigheid directie</li>
          </ul>
          <h4>Wat indien deze gegevens gelekt worden?</h4>
          <p>
            Mocht er zich een gegevenslek voordoen met ongunstige gevolgen voor
            je persoonsgegevens, dan word je - maximaal 72u nadat dit door ons
            geweten is - persoonlijk verwittigd in de door de wet voorziene
            omstandigheden.
          </p>
          <h4>Hoe worden deze gegevens opgeslagen?</h4>
          <p>Deze gegevens worden opgeslagen op:</p>
          <h5>Keuzes voor persoonsgegevens</h5>
          <ul>
            <li>
              de gegevens worden opgeslagen via Teamleader, Dropbox en Google
              mail
            </li>
            <li>
              wij beschikken dus niet intern over een eigen server, alle
              gegevens worden op de Cloud bewaard
            </li>
            <li>
              onze werknemers hebben toegang tot je persoonlijke informatie,
              enkel zij die deze informatie nodig hebben om taken naar behoren
              uit te voeren
            </li>
          </ul>
          <h5>De servers van de website provider</h5>
          <ul>
            <li>de datacenters zijn fysiek ondergebracht in Brussel</li>
            <li>
              de hosting provider bezit als enige in Belgie over de ISO
              27001:2013 norm, ontstaan uit de Engelse "Code of Practice for
              Information Security Management (BS7799), verwijst naar een
              management systeem speciaal voor informatieveiligheid (Information
              Security Management System ISMS) en specificeert hoe u security
              risico's aantoonbaar kunt beheersen. Als invulling van een ISMS
              specificeert de ISO 27001:2013 norm dat u een scope en beleid
              definieert, een risicoanalyse uitvoert, voor de geïdentificeerde
              risico's maatregelen selecteert, implementeert en beheert
              (resources toewijst) en tot slot het voorgaande monitort en
              reviewt (cyclisch).
            </li>
          </ul>
          <h4>Waar worden deze gegevens opgeslagen?</h4>
          <p>
            De datacenters zijn fysiek ondergebracht in Brussel. Onze interne
            systemen staan op geen enkele server opgeslagen, aangezien wij alles
            bewaren op de Cloud.
          </p>
          <h4>Hoe lang worden deze gegevens bewaard?</h4>
          <p>
            De gegevens van een klant worden in overeenstemming met het
            garantietermijn van het product bewaard. Aangezien wij 25 jaar
            garantie geven op bepaalde producten zijn wij dan ook genoodzaakt om
            de klantgegevens gedurende 25 jaar te bewaren.
          </p>
          <h4>Waarvoor worden deze gegevens bewaard en gebruikt?</h4>
          <p>
            Deze gegevens worden niet gebruikt, enkel om u te contacteren ivm.
            uw vraag. Wij voegen uw gegevens ook niet toe aan een mailing list.

            <br />De door ons verwerkte persoonsgegevens zijn in beginsel niet
            toegankelijk voor derden en worden louter intern gebruikt. Doordat
            wij garantie zetten op bepaalde producten, is het van belang dat wij
            de gegevens bewaren. De gegevens zijn enkel intern toegankelijk en
            worden niet gebruikt voor extern mailverkeer. Enkel de werknemers
            van BeliVert ontvangen uw persoonsgegevens. Uw gegevens worden ook
            niet doorgegeven aan een derde land buiten de Europese Economische
            Ruimte.
          </p>
          <h4>Worden er ook cookies opgeslagen?</h4>
          <p>
            Identificatiecookies worden gebruikt om bezoekers te identificeren
            om de beheersapplicatie te gebruiken om de website te kunnen
            bewerken.

            <br />Functionele cookies worden gebruikt om die beheersapplicatie
            goed te kunnen laten werken (waar ben je, wat ben je aan het doen)
            maar is enkel bruikbaar door beheerders.

            <br />Analytische cookies zorgen er voor dat we onze website kunnen
            analyseren. Hierdoor weten wij hoeveel bezoekers er op onze site
            komen, hoe lang ze zijn gebleven, waar ze op hebben geklikt, welke
            onderdelen populair zijn, enz. Ook kunnen we zien welke problemen
            bezoekers hebben. Zo kunnen we onze website verbeteren. Wij doen
            hiervoor ook een beroep op cookies van derden zoals Google
            Analytics. De gegenereerde informatie over je gebruiksgedrag op onze
            websites wordt verzameld en overgebracht naar en door Google, waar
            ze worden opgeslagen op servers in de Verenigde Staten.

            <br />Indien je website optionele cookies gebruikt, en er dus
            bepaalde niet-functionele cookies uitgeschakeld kunnen worden, wordt
            er mogelijks een pop-up getoond om deze af te wijzen bij je bezoek.
            Verder kan je op elk moment via de instellingen van je browser
            (meestal onder "Help" of "Internetopties") alle geïnstalleerde
            cookies uitschakelen of verwijderen van je computer of mobiel
            eindtoestel. Elk type browser heeft zijn eigen manier van het
            beheren van cookies. De informatie kan je vinden op de website van
            de browser die je gebruikt. Hou er rekening mee dat het veranderen
            van de instellingen er voor kan zorgen dat de website niet goed meer
            werkt.
          </p>
          <h4>Hoe kan ik mijn gegevens opvragen, bewerken of verwijderen?</h4>
          <p>
            U heeft als bezoeker het recht om inzage te vragen van uw
            persoonsgegevens, om uw persoonsgegevens te laten wissen, een
            beperking van verwerking aan te vragen, de gegeven toestemming ten
            allen tijde in te trekken of klacht in te dienen. Indien je ons wil
            bereiken om persoonsgegevens aan te passen of te verwijderen, dan
            kan dat onder andere via ons kantoor, schriftelijk, telefonisch of
            via sociale media. Alle algemene contactinformatie vind je hier
            terug: http(s)://www.belivert.be/contact.
          </p>
        </div>
      </b-modal>
      <b-modal
        id="TermsConditions"
        centered
        title="Algemene voorwaarden"
        ok-only
        size="lg"
        ok-variant="success"
        scrollable
      >
        <div class="px-3">
          <div
            id="dnn_ctr497_ContentPane"
            class="dtxContainerContentPaneVolledig"
          >
            <!-- Start_Module_497 -->
            <div
              id="dnn_ctr497_ModuleContent"
              class="DNNModuleContent ModduotixBeheerArtikelDetailC"
            >
              <div class="dtx-artikel-tekstblok dtx-artikel-tekstblok-normaal">
                <h3 class="dtx-artikel-tekstblok-titel">
                  Identiteit van de ondernemer
                </h3>
                <p>
                  <strong>Naam ondernemer</strong><br />
                  BeliVert BV (BeliVert genoemd)
                </p>

                <p>
                  <strong>Vestigingsadres &nbsp;</strong><br />
                  Schatting 42F<br />
                  8210 Zedelgem<br />
                  België
                </p>

                <p>
                  <strong>Telefoonnummer</strong><br />
                  +32 (0)50 25 02 40
                </p>

                <p>
                  <strong>E-mailadres</strong><br />
                  hello@belivert.be
                </p>

                <p>
                  <strong>Ondernemingsnummer&nbsp;</strong><br />
                  0831.630.290
                </p>

                <p>
                  <strong>BTW identificatienummer</strong><br />
                  BE 0831.630.290
                </p>
              </div>

              <div class="dtx-artikel-tekstblok dtx-artikel-tekstblok-normaal">
                <h3 class="dtx-artikel-tekstblok-titel">
                  Gebruik van de website
                </h3>
                <p>
                  Deze gebruiksvoorwaarden zijn van toepassing op de website
                  http(s)://www.belivert.be/. Door gebruik te maken van deze
                  website, aanvaardt u deze en verbindt u zich ertoe deze
                  gebruiksvoorwaarden na te leven en te aanvaarden.
                </p>
              </div>

              <div class="dtx-artikel-tekstblok dtx-artikel-tekstblok-normaal">
                <h3 class="dtx-artikel-tekstblok-titel">
                  Intellectueel eigendom
                </h3>
                <p>
                  Het gebruik van de informatie op deze website is gratis zolang
                  u deze informatie niet kopieert, verspreidt of op een andere
                  manier gebruikt of misbruikt. U mag de informatie op deze
                  website alleen hergebruiken volgens de regelingen van het
                  dwingend recht.
                </p>

                <p>
                  Zonder uitdrukkelijke schriftelijke toestemming van BeliVert
                  is het niet toegestaan tekst, fotomateriaal of andere
                  materialen op deze website her te gebruiken. Het intellectueel
                  eigendom berust bij BeliVert.
                </p>
              </div>

              <div class="dtx-artikel-tekstblok dtx-artikel-tekstblok-normaal">
                <h3 class="dtx-artikel-tekstblok-titel">Rechtmatig gebruik</h3>
                <p>
                  U verbindt zich er onder meer toe om de informatie niet op een
                  onrechtmatige, onwettelijke manier te gebruiken:
                </p>

                <ul>
                  <li>
                    U verbindt zich er toe om geen gebruik te maken van de
                    website waardoor de website wordt beschadigd, vervormd,
                    onderbroken, stopgezet of minder efficiënt wordt gemaakt.
                  </li>
                  <li>
                    U verbindt zich er toe om de website niet te gebruiken voor
                    transmissie of posting van computervirussen, voor
                    transmissie of posting van illegaal of onrechtmatig
                    materiaal of van materiaal dat op enige wijze ongepast is
                    (met inbegrip van maar niet beperkt tot materiaal met een
                    lasterlijk, obsceen of bedreigend karakter).
                  </li>
                  <li>
                    U verbindt zich er toe om de website niet zodanig te
                    gebruiken dat er een inbreuk wordt gepleegd op de rechten
                    van een natuurlijke persoon, rechtspersoon of vereniging,
                    zoals onder meer, doch niet beperkt tot de rechten van
                    privacy en intellectuele eigendom.
                  </li>
                  <li>
                    U verbindt zich er toe om de website niet te gebruiken voor
                    posting en transmissie van materiaal met promotionele of
                    publicitaire doeleinden zonder voorafgaandelijke toestemming
                    van BeliVert, behalve als daarom verzocht werd door de
                    ontvanger.
                  </li>
                </ul>
              </div>

              <div class="dtx-artikel-tekstblok dtx-artikel-tekstblok-normaal">
                <h3 class="dtx-artikel-tekstblok-titel">
                  Correctheid van informatie
                </h3>
                <p>
                  Voor de prijzen die op onze website staan, geldt dat wij
                  streven naar een zo zorgvuldig mogelijke weergave van de
                  realiteit en de bedoelde prijzen. Fouten die daarbij ontstaan
                  en herkenbaar zijn als programmeer dan wel typfouten, vormen
                  nooit een aanleiding om een contract dan wel overeenkomst met
                  BeliVert te mogen claimen of te veronderstellen. De in het
                  aanbod van producten of diensten genoemde prijzen zijn
                  inclusief btw, tenzij anders vermeld.
                </p>

                <p>
                  Het aanbod bevat een volledige en nauwkeurige omschrijving van
                  de aangeboden producten en/of diensten. De beschrijving is
                  voldoende gedetailleerd om een goede beoordeling van het
                  aanbod mogelijk te maken. Alle afbeeldingen, specificaties
                  gegevens in het aanbod zijn indicatie en kunnen geen
                  aanleiding zijn tot schadevergoeding of ontbinding van de
                  overeenkomst.
                </p>

                <p>
                  BeliVert streeft naar een zo actueel mogelijke website. Mocht
                  ondanks deze inspanningen de informatie van of de inhoud op
                  deze website onvolledig en of onjuist zijn, dan kunnen wij
                  daarvoor geen aansprakelijkheid aanvaarden.
                </p>

                <p>
                  De informatie en/of producten op deze website worden
                  aangeboden zonder enige vorm van garantie en of aanspraak op
                  juistheid. Wij behouden ons het recht voor om deze materialen
                  te wijzigen, te verwijderen of opnieuw te plaatsen zonder
                  enige voorafgaande mededeling. BeliVert aanvaardt geen
                  aansprakelijkheid voor enige informatie die op websites staat
                  waarnaar wij via hyperlinks verwijzen.
                </p>

                <p>
                  Als er via de website rechtstreeks of onrechtstreeks adviezen
                  worden gegeven voor het nemen van medische, juridische,
                  financiële of andere persoonlijke of zakelijke beslissingen,
                  dan worden die adviezen zonder vorm van garantie gegeven en
                  zonder dat BeliVert daarvoor aansprakelijk kan gesteld worden.
                  U moet altijd een deskundige ter zake raadplegen voor
                  bijkomende inlichtingen omtrent het op uw situatie
                  toegespitste advies.
                </p>

                <p>
                  Elk aanbod bevat zodanige informatie, dat voor de consument
                  duidelijk is wat de rechten en verplichtingen zijn, die aan de
                  aanvaarding van het aanbod zijn verbonden. Dit betreft in het
                  bijzonder:
                </p>

                <ul>
                  <li>de prijs inclusief belastingen;</li>
                  <li>de eventuele kosten van verzending;</li>
                  <li>
                    de wijze waarop de overeenkomst tot stand zal komen en welke
                    handelingen daarvoor nodig zijn;
                  </li>
                  <li>
                    de wijze van betaling, aflevering en uitvoering van de
                    overeenkomst;
                  </li>
                  <li>
                    de termijn voor aanvaarding van het aanbod, dan wel de
                    termijn waarbinnen de ondernemer de prijs garandeert;
                  </li>
                  <li>
                    de hoogte van het tarief voor communicatie op afstand indien
                    de kosten van het gebruik van de techniek voor communicatie
                    op afstand worden berekend op een andere grondslag dan het
                    reguliere basistarief voor het gebruikte communicatiemiddel;
                  </li>
                  <li>
                    of de overeenkomst na de totstandkoming wordt gearchiveerd,
                    en zo ja op welke wijze deze voor de consument te raadplegen
                    is;
                  </li>
                  <li>
                    de manier waarop de consument, voor het sluiten van de
                    overeenkomst, de door hem in het kader van de overeenkomst
                    verstrekte gegevens kan controleren en indien gewenst
                    herstellen;
                  </li>
                  <li>
                    de eventuele andere talen waarin, naast het Nederlands, de
                    overeenkomst kan worden gesloten;
                  </li>
                </ul>
              </div>

              <div class="dtx-artikel-tekstblok dtx-artikel-tekstblok-normaal">
                <h3 class="dtx-artikel-tekstblok-titel">Herroepingsrecht</h3>
                <p>
                  <strong
                    >Wanneer is het herroepingsrecht van toepassing?</strong
                  >
                </p>

                <p>
                  Wanneer u via een webshop, een callcenter of een
                  postorderbedrijf goederen of diensten koopt, is dat een
                  aankoop op afstand en is het herroepingsrecht van toepassing.
                  Er is bij zo’n aankoop op afstand bij het tot stand komen van
                  de koop geen fysiek contact tussen koper en verkoper. De
                  transactie verloopt dan via een op afstand georganiseerd
                  verkoopsysteem, zoals bijvoorbeeld een webshop of een online
                  formulier.
                </p>

                <p>Bij levering van producten</p>

                <ul>
                  <li>
                    Bij de aankoop van producten heeft de consument de
                    mogelijkheid de overeenkomst zonder opgave van redenen te
                    ontbinden gedurende 14 dagen. Deze bedenktermijn gaat in op
                    de dag na ontvangst van het product door de consument of een
                    vooraf door de consument aangewezen en aan de ondernemer
                    bekend gemaakte vertegenwoordiger.
                  </li>
                  <li>
                    Tijdens de bedenktijd zal de consument zorgvuldig omgaan met
                    het product en de verpakking. Hij zal het product slechts in
                    die mate uitpakken of gebruiken voor zover dat nodig is om
                    te kunnen beoordelen of hij het product wenst te behouden.
                    Indien hij van zijn herroepingsrecht gebruik maakt, zal hij
                    het product met alle geleverde toebehoren en - indien
                    redelijkerwijze mogelijk - in de originele staat en
                    verpakking aan de ondernemer retourneren, conform de door de
                    ondernemer verstrekte redelijke en duidelijke instructies.
                  </li>
                  <li>
                    Wanneer de consument gebruik wenst te maken van zijn
                    herroepingsrecht is hij verplicht dit binnen 14 dagen, na
                    ontvangst van het product, &nbsp;kenbaar te maken aan de
                    ondernemer. Het kenbaar maken dient de consument te doen
                    middels het modelformulier. Nadat de consument kenbaar heeft
                    gemaakt gebruik te willen maken van zijn herroepingsrecht
                    dient de klant het product binnen 14 dagen retour te sturen.
                    De consument dient te bewijzen dat de geleverde zaken tijdig
                    zijn teruggestuurd, bijvoorbeeld door middel van een bewijs
                    van verzending.
                  </li>
                  <li>
                    Indien de klant na afloop van de genoemde termijnen niet
                    kenbaar heeft gemaakt gebruik te willen maken van zijn
                    herroepingsrecht resp. het product niet aan de ondernemer
                    heeft teruggezonden, is de koop een feit.
                  </li>
                </ul>

                <p>Bij levering van diensten</p>

                <ul>
                  <li>
                    Bij levering van diensten heeft de consument de mogelijkheid
                    de overeenkomst zonder opgave van redenen te ontbinden
                    gedurende ten minste 14 dagen, ingaande op de dag van het
                    aangaan van de overeenkomst.
                  </li>
                  <li>
                    Om gebruik te maken van zijn herroepingsrecht, zal de
                    consument zich richten naar de door de ondernemer bij het
                    aanbod en/of uiterlijk bij de levering ter zake verstrekte
                    redelijke en duidelijke instructies.
                  </li>
                </ul>

                <p>
                  Kosten in geval van herroeping<br />
                  Indien de consument gebruik maakt van zijn herroepingsrecht,
                  komen ten hoogste de kosten van terugzending voor zijn
                  rekening.
                </p>

                <ul>
                  <li>
                    Indien de consument een bedrag betaald heeft, zal de
                    ondernemer dit bedrag zo spoedig mogelijk, doch uiterlijk
                    binnen 14 dagen na herroeping, terugbetalen. Hierbij is wel
                    de voorwaarde dat het product reeds terug ontvangen is door
                    de webwinkelier of sluitend bewijs van complete terugzending
                    overlegd kan worden. Terugbetaling zal geschieden via de
                    zelfde betaalmethode die door de consument is gebruikt
                    tenzij de consument nadrukkelijk toestemming geeft voor een
                    andere betaalmethode.
                  </li>
                  <li>
                    Bij beschadiging van het product door onzorgvuldige omgang
                    door de consument zelf is de consument aansprakelijk voor
                    eventuele waardevermindering van het product.
                  </li>
                  <li>
                    De consument kan niet aansprakelijk worden gesteld voor
                    waardevermindering van het product wanneer door de
                    ondernemer niet alle wettelijk verplichte informatie over
                    het herroepingsrecht is verstrekt, dit dient te gebeuren
                    voor het sluiten van de koopovereenkomst.
                  </li>
                </ul>

                <p><strong>Uitsluiting herroepingsrecht</strong></p>

                <ul>
                  <li>
                    De ondernemer kan het herroepingsrecht van de consument
                    uitsluiten voor producten zoals omschreven in de items
                    hieropvolgend. De uitsluiting van het herroepingsrecht geldt
                    slechts indien de ondernemer dit duidelijk in het aanbod,
                    althans tijdig voor het sluiten van de overeenkomst, heeft
                    vermeld.
                  </li>
                  <li>
                    Uitsluiting van het herroepingsrecht is slechts mogelijk
                    voor producten: die door de ondernemer tot stand zijn
                    gebracht overeenkomstig specificaties van de consument; die
                    duidelijk persoonlijk van aard zijn; die door hun aard niet
                    kunnen worden teruggezonden; die snel kunnen bederven of
                    verouderen; waarvan de prijs gebonden is aan schommelingen
                    op de financiële markt waarop de ondernemer geen invloed
                    heeft; voor losse kranten en tijdschriften; voor audio- en
                    video-opnamen en computersoftware waarvan de consument de
                    verzegeling heeft verbroken. voor hygiënische producten
                    waarvan de consument de verzegeling heeft verbroken.
                  </li>
                  <li>
                    Uitsluiting van het herroepingsrecht is slechts mogelijk
                    voor diensten: betreffende logies, vervoer,
                    restaurantbedrijf of vrijetijdsbesteding te verrichten op
                    een bepaalde datum of tijdens een bepaalde periode; waarvan
                    de levering met uitdrukkelijke instemming van de consument
                    is begonnen voordat de bedenktijd is verstreken; betreffende
                    weddenschappen en loterijen.
                  </li>
                </ul>
              </div>

              <div class="dtx-artikel-tekstblok dtx-artikel-tekstblok-normaal">
                <h3 class="dtx-artikel-tekstblok-titel">Wijzigingen</h3>
                <p>
                  Mochten deze algemene voorwaarden wijzigen, dan vindt u de
                  meest recente versie op deze pagina.
                </p>
              </div>

              <div class="dtx-artikel-tekstblok dtx-artikel-tekstblok-normaal">
                <h3 class="dtx-artikel-tekstblok-titel">Statistieken</h3>
                <p>
                  BeliVert kan anonieme of geaggregeerde gegevens verzamelen van
                  niet-persoonlijke aard, zoals browsertype of IP-adres, het
                  besturingsprogramma dat u gebruikt of de domeinnaam van de
                  website langs waar u naar de website gekomen bent, of
                  waarlangs u die verlaat.
                </p>
              </div>

              <div class="dtx-artikel-tekstblok dtx-artikel-tekstblok-normaal">
                <h3 class="dtx-artikel-tekstblok-titel">
                  Toepasselijke wetgeving en bevoegde rechtbanken
                </h3>
                <p>
                  De Belgische wetgeving is van toepassing op deze website met
                  uitzondering van de Belgische doorverwijzingsregels uit het
                  Belgisch internationaal privaatrecht. In geval van
                  betwistingen ivm. deze website zijn alleen de rechtbanken van
                  het gerechtelijk arrondissement Gent bevoegd.
                </p>
              </div>

              <div class="dtx-artikel-tekstblok dtx-artikel-tekstblok-normaal">
                <h3 class="dtx-artikel-tekstblok-titel">
                  Privacybeleid en gebruik van cookies
                </h3>
                <p>
                  Wij hebben respect voor uw privacy en het gebruik, bewaren en
                  verwerken van persoonsgegevens. Het gebruik van deze website
                  betekent dat u akkoord bent met ons privacybeleid. Deze
                  website maakt gebruik van cookies. Meer informatie vindt u in
                  ons privacybeleid.
                </p>
              </div>
            </div>
            <!-- End_Module_497 -->
          </div>
        </div>
      </b-modal>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss" scoped>
.footer {
  background-color: #fafafa !important;
}
.cursor-pointer {
  cursor: pointer;
}
.text-secondary {
  color: #74a301 !important;
}
</style>
