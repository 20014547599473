<template>
  <div class="home">
    <Banner />
    <WaaromZonnepanelen />
    <Footer />
  </div>
</template>

<script>
import Banner from "../componentsv2/Banner.vue";
import WaaromZonnepanelen from "../componentsv2/WaaromZonnepanelen.vue";
import Footer from "../componentsv2/Footer.vue";

export default {
  name: "Homev2",
  components: {
    Banner,
    WaaromZonnepanelen,
    Footer,
  },
};
</script>
<style lang="scss" scoped>
.home {
  background: url(../assets/bg.png) no-repeat;
  background-size: 50%;
  background-position: top right;
  @media (max-width: 499.98px) {
    background-image: none;
  }
}
</style>
