<template>
  <div class="banner">
    <b-container v-if="thirdFormShow" class="p-md-5 fluid-lg">
      <b-row cols="px-0 px-md-5 py-0 py-md-4">
        <div class="col-12 col-md-6 col-lg-6 pt-sm-0 pt-4">
          <div class="d-flex align-items-center ml-md-5 ml-2 mb-3">
            <a href="#"><img src="../assets/headerLogo1.png" width="85px" /></a>
            <a href="#"
              ><img src="../assets/headerLogo2.png" width="50px" class="mx-2"
            /></a>
            <a href="#"><img src="../assets/headerLogo3.png" width="90px" /></a>
          </div>
          <ThirdForm />
        </div>
      </b-row>
    </b-container>
    <SecondFormSection v-else-if="secondFormShow" />
    <!--  -->
    <b-container v-else-if="first_form" class="p-md-5 fluid-lg">
      <b-row cols="px-0 px-md-5 py-0 py-md-4">
        <div class="col-12 col-md-6 col-lg-6 pt-sm-0 pt-4">
          <div class="d-flex align-items-center ml-md-4 ml-2 mb-3">
            <a href="#"><img src="../assets/headerLogo1.png" width="85px" /></a>
            <a href="#"
              ><img src="../assets/headerLogo2.png" width="50px" class="mx-2"
            /></a>
            <a href="#"><img src="../assets/headerLogo3.png" width="90px" /></a>
          </div>
          <h2 class="font-weight-bold pt-3 mb-3 color-green">
            Kom jij in aanmerking voor <br />besparen met zonnepanelen?
          </h2>
          <h4 class="font-weight-bold">
            Doe de postcodecheck <br />en check of jij kan besparen!
          </h4>
          <img src="../assets/arrow.png" class="arrow" />
          <FirstForm />
        </div>
        <!--  -->
        <div class="col-12 col-md-6 align-self-center">
          <div class="bg-white shadow p-3 p-md-4 banner__ul my-5 my-md-0">
            <ul class="banner__ul__styling">
              <li class="banner__ul__text">
                <i class="fa-solid fa-check banner__icoColor"></i>
                <span class="font-weight-bold"> Minder kosten:</span> geniet van
                de overheids subsidie
              </li>
              <li class="banner__ul__text">
                <i class="fa-solid fa-check banner__icoColor"></i
                ><span class="font-weight-bold"> Bespaar kosten</span> op je gas
                en energierekening door betere isolatie.
              </li>
              <li class="banner__ul__text">
                <i class="fa-solid fa-check banner__icoColor"></i> Vergelijk
                <span class="font-weight-bold">gratis</span> vrijblijvende
                offertes
              </li>
              <li class="banner__ul__text">
                <i class="fa-solid fa-check banner__icoColor"></i> Alleen
                <span class="font-weight-bold">betrouwbare vakmannen</span> voor
                je klus
              </li>
              <li class="banner__ul__text">
                <i class="fa-solid fa-check banner__icoColor"></i> In
                {{ new Date().getFullYear() }}
                geniet u van een
                <span class="font-weight-bold">premie vanuit de overheid</span>
              </li>
            </ul>
          </div>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FirstForm from "./FirstForm.vue";
import SecondFormSection from "./SecondFormSection.vue";
import ThirdForm from "./ThirdForm.vue";
export default {
  name: "Banner",
  components: { FirstForm, SecondFormSection, ThirdForm },
  computed: mapGetters(["secondFormShow", "first_form", "thirdFormShow"]),
};
</script>

<style lang="scss" scoped>
@media (max-width: 767.98px) {
  h2 {
    font-size: 23px;
  }
  h3 {
    font-size: 20px;
  }
}
.color-green {
  color: #418d4a;
}
.font-weight-bold {
  font-weight: 700 !important;
}
.banner {
  .fluid-lg {
    @media (min-width: 768px) and (max-width: 991.98px) {
      max-width: 100%;
      padding: 0 !important;
    }
  }
  &__ul {
    border-radius: 8px !important;
    border-left: 2px solid #eee !important;
    border-color: #418d4a !important;
  }
  &__icoColor {
    color: #418d4a;
  }
  &__ul__styling {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  &__ul__text {
    font-size: 15px;
  }
  .arrow {
    width: 60px;
    display: flex;
    margin-left: auto;
    margin-top: -60px;
    margin-right: 60px;
  }
}
</style>
