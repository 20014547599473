import axios from "axios";
import { getStreet } from "./newStreetAction";
import { RepositoryFactory } from "../../repository/RepositoryFactory";

let leads = RepositoryFactory.get("leads");
const state = {
  lng: 0,
  lat: 0,
  zip: null,
  street: null,
  cityName: null,
  city: null,
  fullLoader: false,
};

const getters = {
  lng: (state) => state.lng,
  lat: (state) => state.lat,
  zip: (state) => state.zip,
  city: (state) => state.city,
  street: (state) => state.street,
  cityName: (state) => state.cityName,
  fullLoader: (state) => state.fullLoader,
};

const actions = {
  async getLocation({ commit }, payload) {
    // try {
    //   commit("setFullLoader", true);
    //   const response = await leads.getStreet(payload);

    //   const street = response.data.street;
    //   const city = response.data.city;
    //   commit("city", city);
    //   commit("street", street);
    //   commit("setFullLoader", false);

    //   console.log(street, "street");
    //   console.log(city, "city");

    //   // return {
    //   //   success: true,
    //   //   city: city,
    //   //   street: street,
    //   // };
    // } catch (error) {
    //   commit("setFullLoader", false);
    // alert("getStreet error");
    // }

    try {
      const options = {
        method: "GET",
        url: "https://maps.googleapis.com/maps/api/geocode/json",
        params: {
          address: `${payload.zip}, Belgie `,
          // address: `${state.street} ${payload.city} ${payload.zip} , Belgie `,
          key: "AIzaSyAZ4MO64L0GEOB9TmzfYgeYbGnZGX9AAUc",
        },
      };
      axios.request(options).then(function (response) {
        // console.log(response.data.results[0].geometry.location);

        const lng = response.data.results[0].geometry.location.lng;
        commit("lng", lng);
        const lat = response.data.results[0].geometry.location.lat;
        commit("lat", lat);
        // const cityName = response.data.results[0].formatted_address;
        // commit("cityName", payload.city);
        commit("zip", payload.zip);
      });
    } catch (error) {
      alert(error);
    }
  },
};

const mutations = {
  lng(state, payload) {
    state.lng = payload;
  },
  lat(state, payload) {
    state.lat = payload;
  },
  city(state, payload) {
    state.city = payload;
  },
  cityName(state, payload) {
    state.cityName = payload;
  },
  street(state, payload) {
    state.street = payload;
  },
  setFullLoader(state, payload) {
    state.fullLoader = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
