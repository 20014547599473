var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('h3',{staticClass:"font-weight-bold m-0"},[_vm._v(" Goed nieuws! Jouw woning in postcodegebied "),_c('span',{staticClass:"text-green"},[_vm._v(" "+_vm._s(_vm.first_form.zip)+" ")]),_vm._v(" in "),_vm._v(" komt in aanmerking voor gratis zonnepanelen offertes. ")]),_c('div',{staticClass:"borderblue h5 pb-3 px-3 pt-2 my-2 my-md-4 bg-white font-weight-bold"},[_vm._v(" + geniet in "+_vm._s(new Date().getFullYear())+" ook van een "),_c('br'),_vm._v("premie vanuit de overheid. ")]),_c('div',{staticClass:"my-2 my-md-4"},[_c('div',[_c('GmapMap',{staticClass:"map-height",staticStyle:{"width":"100%","height":"350px"},attrs:{"options":{
          zoomControl: false,
          fullscreenControl: false,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: true,
          disableDefaultUi: false,
        },"zoom":13,"center":{ lng: this.lng, lat: this.lat },"map-type-id":"terrain"}})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center ml-md-4 ml-2 mb-3"},[_c('a',{attrs:{"href":"#"}},[_c('img',{attrs:{"src":require("../assets/headerLogo1.png"),"width":"85px"}})]),_c('a',{attrs:{"href":"#"}},[_c('img',{staticClass:"mx-2",attrs:{"src":require("../assets/headerLogo2.png"),"width":"50px"}})]),_c('a',{attrs:{"href":"#"}},[_c('img',{attrs:{"src":require("../assets/headerLogo3.png"),"width":"90px"}})])])
}]

export { render, staticRenderFns }